<template>
    <div>
        <div class="cl" style="margin-left:-10px;margin-right:-10px;">
            <div class="z" style="width: 206px;margin:0 10px;" v-for="(item,index) in dataList">
                <div class="hand hy-data-item padding-20 text-center" :class="{on:dataIndex === index}" @click="dataIndex = index">
                    <lee-img-icon class="img" :color="dataIndex === index ? '#fff' : '#f0f0f0'" :url="'/images/icon/'+item.img" size="64"></lee-img-icon>
                    <div class="margin-top-30 text-center f18 dark-1 title">{{item.title}}</div>
                </div>
            </div>
        </div>
        <div class="margin-top-40 bg-light border-radius-10 padding-30">
            <div class="cl" style="height:40px;">
                <div class="z bg-green light border-radius text-center" style="width: 40px;height:40px;">
                    <i class="el-icon-document f26" style="margin-top:7px;"></i>
                </div>
                <div class="z f28 margin-left" style="line-height:40px;">数据平台行业数据分析报告—{{dataList[dataIndex].title}}</div>
                <div class="y green hand f20" style="line-height:40px;" @click="toHydata()">MORE</div>
            </div>
            <lee-index-hydata1 v-show="dataIndex === 0" :year="nowYear"></lee-index-hydata1>
            <lee-index-hydata2 v-show="dataIndex === 1" :year="nowYear"></lee-index-hydata2>
            <lee-index-hydata3 v-show="dataIndex === 2" :year="nowYear"></lee-index-hydata3>
            <lee-index-hydata4 v-show="dataIndex === 3" :year="nowYear"></lee-index-hydata4>
            <lee-index-hydata5 v-show="dataIndex === 4" :year="nowYear"></lee-index-hydata5>
            <lee-index-hydata6 v-show="dataIndex === 5" :year="nowYear"></lee-index-hydata6>
<!--            <lee-index-hydata7 v-show="dataIndex === 6" :year="nowYear"></lee-index-hydata7>-->
        </div>
    </div>
</template>

<script>
export default {
    name: "IndexHyData",
    data(){
        return{
            nowYear:'',
            dataIndex:0,
            dataList:[
                {
                    img:'icon_9.png',
                    title:'冷藏车',
                    url:'/hydata/coldcar',
                    data:null
                },
                {
                    img:'icon_10.png',
                    title:'冷库',
                    url:'/hydata/coldstorage',
                    data:null
                },
                {
                    img:'icon_11.png',
                    title:'冷链物流',
                    url:'/hydata/coldchain',
                    data:null
                },
                {
                    img:'icon_12.png',
                    url:'/hydata/harvest',
                    title:'产量',
                },
                {
                    img:'icon_13.png',
                    title:'进出口',
                    url:'/hydata/inout'
                },
                // {
                //     img:'icon_14.png',
                //     title:'农批',
                //     url:'/hydata/agriculture'
                // },
                // {
                //     img:'icon_15.png',
                //     title:'特色农品',
                // }
            ]
        }
    },
    created() {
        let _now = new Date();

        this.nowYear = _now.getFullYear() - 1;
    },
    mounted() {

    },
    methods:{
        toHydata(){
            let _url = this.dataList[this.dataIndex].url;

            if(_url){
                this.$router.push({path:_url});
            }
        }
    }
}
</script>
<style scoped>
.hy-data-item{background: #fff;border-radius: 10px;}
.hy-data-item.on,.hy-data-item:hover{background: #7ED321;}
.hy-data-item.on .title,.hy-data-item:hover .title{color: #fff;}
.hy-data-item.on .img,.hy-data-item:hover .img{background: #fff !important;}
</style>
