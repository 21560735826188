<template>
    <lee-page bg-img="/images/bg_head_9.jpg" :title-class="$isMobile ? '' : 'margin-top-100'" :height="$isMobile ? '100' : '280'" :back="$isMobile" :title="$isMobile ? '' : title" :bread="[{title:'新闻资讯',url:'/news'},{title:data.title}]" v-if="data">
        <div :class="!$isMobile ? 'bg-img-2 padding-bottom-400 padding-top-100' : 'padding-left-right'" :style="{backgroundColor: !$isMobile ? '#f8f9fa' : '',marginTop:$isMobile ? '-40px' : ''}">
            <div :class="$isMobile ? '' : 'wrap1100'">
                <div class="box-shadow bg-light" :class="$isMobile ? 'padding border-radius-5' : 'padding-30'">
                    <div class="green-line">
                        <div class="cl" :class="$isMobile ? 'f18' : 'f34'">
                            <img src="/images/icon/icon_30.png" height="22" class="no-v-mid" v-if="!$isMobile"/>
                            {{data.title}}
                        </div>
                    </div>
                  <template v-if="data.check.type === 'ok'">
                    <div class="f16 dark-1 base-content" v-html="data.html_content ? data.html_content : ''"></div>
                    <div class="margin-top-20" v-if="data.files && data.files.length > 0">
                      <lee-vip-file-down :url="item.url" :filename="item.name ? item.name : '文件'" v-for="(item,index) in data.files" class="margin-right margin-bottom" akey="standard" :tid="data.id"></lee-vip-file-down>
                    </div>
	                  <lee-good-view class="margin-top-50" :id="data.id" akey="news" :open-like="data.open_like === 1" :open-favorite="data.open_favorite === 1" :open-comment="data.open_comment === 1"></lee-good-view>
                  </template>
                  <vip-check-button :datas="data" :check="data.check" :price="data.price" :title="data.title" pay-title="购买资讯详情" pay-sub-title="资讯详情金额" type="news" v-else></vip-check-button>
<!--                  <lee-comment-view total-count="30" akey="news" :id="data.id" v-if="data.open_comment === 1"></lee-comment-view>-->
                </div>
	            <template v-if="data.check.type === 'ok'">
		            <div class="box-shadow padding-30 bg-light margin-top-30" v-if="data.open_comment === 1">
			            <lee-comment-view total-count="30" akey="news" :id="data.id"></lee-comment-view>
		            </div>
	            </template>
            </div>
        </div>
    </lee-page>
</template>

<script>
export default {
    name: "List",
    data(){
        return{
            title:'',
            id:'',
            data:null,
        }
    },
    created() {
        this.id = this.$route.params.id;
    },
    mounted() {
        this.getDetail();
    },
    methods:{
        getDetail(){
            let _this = this;

            if(this.id) {
                this.$http.post({
                    url: '/News/detail',
                    data: {id: this.id},
                    success(res) {
                        _this.data = res;
                        _this.title = res.title;
                    }
                });
            }
        }
    }
}
</script>
