<template>
    <lee-page bg-img="/images/bg_head_5.jpg" title="新闻资讯" sub-title="News & Information" :search="true"  :bread="[{title:'新闻资讯'}]" height="526" @onSearch="onSearch">
        <div class="bg-img-2"  style="background-color:#f8f9fa">
            <div class="wrap1100 padding-top-50 padding-bottom-400">
                <div class="cl"></div>
                <template v-if="list.length > 0">
	                <div class="text-center dark-3 padding-bottom-30">共查询到 <b class="red">{{total}}</b> 条相关内容</div>
                    <div class="box-shadow bg-light margin-bottom-20 hand" v-for="(item,index) in list" @click="$router.push({path:'/news/detail/'+item.id})">
                        <div class="hide">
                            <div class="margin">
                                <div class="cl">
                                    <div class="z green-line padding-right-20" style="line-height: 30px;">
                                        <img src="/images/icon/icon_30.png" height="22" class="margin-right"/>
                                        <span class="dark-0 vm f18" v-html="item.title"></span>
                                    </div>
                                </div>
                                <div class="f14 dark-3 text-line-2" v-html="item.content"></div>
                            </div>
                        </div>
                    </div>
                    <div class="text-center margin-top-100">
                        <el-pagination
                            :hide-on-single-page="true"
                            background
                            layout="pager"
                            :page-size="pageSize"
                            :current-page.sync="searchForm.page"
                            :total="total"
                            @current-change="pageChange">
                        </el-pagination>
                    </div>
                </template>
                <el-empty description="未查询到相关内容" v-else></el-empty>
            </div>
        </div>
    </lee-page>
</template>

<script>
export default {
    name: "Index",
    data(){
        return{
            list:[],
            pageSize:10,
            total:0,
            searchForm:{
                page:1,
                title:'',
                title_type:''
            }
        }
    },
    created() {
        if(!this.$utils.isEmptyObject(this.$route.query)){
            this.searchForm = {...this.$route.query};
        }

        this.searchForm.page = this.searchForm.page ? parseInt(this.searchForm.page) : 1;
    },
    mounted() {
        this.getList();
    },
    methods:{
        getList(){
            let _this = this;

            this.$http.post({
                url:'/News/listPage',
                data:{...this.searchForm,pageSize:this.pageSize},
                success(res){
                    _this.list = res.list;
                    _this.total = res.totalCount;
                }
            })
        },
        pageChange(page) {
            this.searchForm.page = page;

            this.$router.replace({query:{...this.searchForm}});
            // this.getList();
        },
        onSearch(k){
            console.log(k);
            this.searchForm.title = k.title;
            this.searchForm.title_type = k.title_type;

            this.$router.replace({query:{...this.searchForm}});
        }
    }
}
</script>
