<template>
    <el-row class="box-shadow lee-tab border-radius-5 hide">
        <el-col :span="24 / list.length" v-for="(item,index) in list">
            <div class="text-center hand border-radius-5 hide lee-tab-item" :class="type === item.value ? 'on '+activeClass : ''" @click="change(item)">
                <img :src="item.icon+(type === item.value ? 'on' : 'off') + '.png'" height="20" class="margin-right"/>
                {{item.label}}
            </div>
        </el-col>
    </el-row>
</template>

<script>
export default {
    name: "Tabs",
    data(){
        return{
            type:''
        }
    },
    props:{
        list:{
            type:Array,
            default:()=>{
                return []
            }
        },
        defaultIndex:{
            type:[String,Number],
            default:''
        },
        activeClass:{
            type:String,
            default:'bg-green-line'
        }
    },
    created() {
        if(this.defaultIndex){
            this.type = this.defaultIndex;
        }else{
            if(this.list.length > 0){
                this.type = this.list[0].value;
            }
        }

        if(this.$route.query.mmtype){
          this.type = this.$route.query.mmtype;
        }
        console.log(this.type);
        this.$emit('change',{value:this.type})
    },
    methods:{
        change(item){
            // this.$emit('change',item);
            this.type = item.value;
            this.$router.replace({query:{mmtype:item.value}})
        }
    }
}
</script>
